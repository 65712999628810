import i18next from "../components/i18n";


export const imageData = [
	{
		id: 1,
		question: i18next.t('requirements.eyewear'),
		name: 'eyeglasses',
	},

	{
		id: 2,
		question: i18next.t('requirements.face_centered'),
		name: 'face_centered',
	},
	{
		id: 4,
		question: i18next.t('requirements.visible'),
		name: 'is_clearly_visible',
	},

	{
		id: 5,
		question: i18next.t('requirements.neutral'),
		name: 'neutral_face',
	},
];
