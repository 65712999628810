import styled from 'styled-components';
import { getGreenFrameStyles } from '../styled';

export const Container = styled.div`
	width: 100%;

	.desktop {
		display: none;

		button {
			margin-top: 1rem;
		}
	}

	@media (min-width: 768px) {
		.desktop {
			display: flex;
			justify-content: space-around;

			.btn {
				margin-left: 2rem;
			}
		}
	}
`;

export const MBContent = styled.div`
	margin-top: 4%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.btn {
		margin-top: 0.1rem;
	}

	.no-bg-btn {
		margin-top: 1.5rem;
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

export const StyledGeneral = styled.div`
	margin-top: 4%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	flex-direction: column;

	.image-preview {
		width: 250px;
		height: 282px;
		margin-bottom: 30px;
		background: #ffffff;
		box-shadow: 0px 0px 46px -8px rgba(0, 0, 0, 0.25);
		border-radius: 15px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			border-radius: 15px;
			object-fit: cover;
			
		}

	}

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-start;
		margin-top: unset;
		border-radius: 20px;

		.image-preview {
			width: 400px;
			height: 390px;
			border-radius: 20px;
			img {
				border-radius: 20px;
			}
		}
	}

	@media (min-width: 1024px) {
		border-radius: 23px;

		.image-preview {
			width: 500px;
			height: 470px;
			border-radius: 23px;
			img {
				border-radius: 23px;
			}
		}
	}

	@media (min-width: 768px) {
		.answers{
			display: grid;
			min-height: 375px;
			grid-template-columns: 492px 55px;
			grid-auto-rows: 75px;
			align-items: center;
			margin-left: 30px;

			.issue{
				color: #EE4B2B;
				font-weight: bold;
			}
		}
	}	


	@media (max-width: 768px) {
		.answers{
			display: grid;
			min-height: 210px;
			grid-template-columns: 250px 55px;
			grid-auto-rows: 70px;
			align-items: center;
			margin-left: 30px;
			
			.question{
				font-size: 1.7rem;
				color: black;
			}

			.issue{
				font-size: 1.7rem;
				color: #EE4B2B;
			}
		}
	}

	.bottom-buttons button{
		margin-right: 30px;
		margin-left: 30px;
	}



`;

export const StyledActions = styled.div`
	margin: 1rem 3rem 0 3rem;

	.answer {
		height: 4.2rem;
		font-size: 12px;
		color: #808080;
		padding-bottom: 1rem;
		padding-top: 0.3rem;
		margin-bottom: 0.5rem;
		width: 100%;
		display: flex;
		justify-content: space-between;

		&.border {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}

		.answer-icon{
			margin-left: 30px;
			position: relative;
			top: -14px;
			border: 1px white solid;
			height: 4.2rem;

			&.yes{
				border: 1px solid #AAFF00;
			}

			&.no{
				border: 1px solid #EE4B2B;
			}

			&.question-mark{
				top: -9px;
			}
		}

		
	}


`;
