import React, { useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';

import {
	Container,
	MBContent,
	StyledGeneral,
} from './image-content.styled';
import Button from '../button/button.comp';
import PhoneCamera from '../phone-camera/phone-camera.comp';
import { useData } from '../../context/data';
import { useCamera } from '../../utils/hooks/use-camera';
import { imageData } from '../../mock-ups/image.data';
import { useImageAction } from '../../utils/hooks/use-image';
import DesktopWebcam from '../desktop-webcam/desktop-webcam.comp';
import ImageReview from '../image-review/image-review.comp';
import { useIsMobile } from '../../utils/hooks/use-is-mobile';

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import MUIButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import i18next from '../i18n'

export default function ImageContent() {
	const { state, capture, uploadImageToS3, uploadImageMB, retakeImage } = useData();
	const { camRef } = useCamera();
	const isMobile = useIsMobile();

	useEffect(() => {
		if (!state.imagePreview) navigate('/image-preview');
	}, [state.imagePreview, isMobile]);

  const { values, handleClick, disabled } = useImageAction();


  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const ConfirmDialog = () => {
    
    const confirmSubmit = () => {
      uploadImageToS3()
      handleDialogClose()
    }
    
    return (
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm-dialog"
        
      >
        <DialogTitle id="alert-dialog-title">
          <span className="mobile-font"> { i18next.t('dialog.title') }</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="mobile-font"> { i18next.t('dialog.description') }</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={confirmSubmit} >
            <span className="mobile-font"> { i18next.t('dialog.yes') }</span>
          </MUIButton>
          <MUIButton onClick={handleDialogClose} autoFocus>
            <span className="mobile-font"> { i18next.t('dialog.no') }</span>
          </MUIButton>
          
        </DialogActions>
      </Dialog>
    )
  }

	const hasImageFeature = (feature_name) => {

		if(state?.imageFeatures && state.imageFeatures.hasOwnProperty(feature_name)){
			
			// This question is reversed: "Did you remove...."? 
			// so we have to answer the opposite
			if(feature_name == 'eyeglasses'){
				return !state.imageFeatures[feature_name]
			}

			// All pictures have white background
			if(feature_name == 'is_white_background'){
				return true
			}
			


			return state.imageFeatures[feature_name]
		}
		else {
			return null
		}

	}

	// Checks if the picture has ALL the desired feactures
	const isAllGood = () => {

		if(typeof state.imageFeatures !== 'object'){
			return false
		}

		// If there are no features returns false
		if(Object.keys(state.imageFeatures).length == 0){
			return false
		}

    // Check proximity
    if('proximity' in state.imageFeatures){
      let proximity = state.imageFeatures['proximity']
      if(proximity != 'ok'){
        return false
      }
    }
    
    // Check for closed eyes
    if('are_eyes_closed' in state.imageFeatures && state.imageFeatures['are_eyes_closed'] == true){
      return false
    }

    // Check for open mouth
    if('is_mouth_open' in state.imageFeatures && state.imageFeatures['is_mouth_open'] == true){
      return false
    }

    // Rest of checks
		return imageData.every( ({name}) => hasImageFeature(name) )
	}
	
	const isAllGoodMemoized = useMemo(isAllGood,[state.imageFeatures])

	// The image or the camera is going to show depends wether we're on mobile
	// and wether state.processedImage already exists
	let ImageComponent = () => {
		
		if(isMobile){
			if(state.processedImage){
				return (
					<div className='image-preview'>
						<img src={state.processedImage} alt='' />
					</div>
				)
			} else {
				return (
					<div className='image-preview'>
						<img src={state.imagePreview} alt='' />
					</div>
				)
			}
		}		
		else {
			if(state.processedImage){
				return <ImageReview />
			} else {
				return <DesktopWebcam />
			}
		}
	};

  

  // The button variant will change depending on two things
  // if we're seeing it in desktop/mobile
  // if the button is the main option
  const getButtonVariant = (main, isMobile) => {
    let variant;

    if(main == true){
      if(!isMobile){
        variant = 'outline'
      }
      else{
        variant = 'solid'
      }
    }

    if(main == false){
      if(!isMobile){
        variant = 'outlineborder'
      }
      else{
        variant = 'noBg'
      }
    }

    return variant;
  }




  const renderNextButton = (main = false) => {
   

    if(isAllGoodMemoized){
      var nextButtonText = i18next.t('button.next')
      var clickHandler = () => {
        uploadImageToS3()
      }
    }
    else{
      var nextButtonText = i18next.t('button.continue_anyways')
      var clickHandler = () => {
        handleDialogOpen()
      }
    }

    let variant = getButtonVariant(main, isMobile)

    return (
      <Button
        text={nextButtonText}
        variant={ variant }
        size='lg'
        className='btn'
        onClick={clickHandler}
        disabled={disabled}
      />
    )
    
  }

  const renderCaptureButton = (main = false) => {
    
    let variant = getButtonVariant(main, isMobile)

    if(state.imagePreview){
      return (
        <Button
          text= { i18next.t('button.retake_photo') }
          variant={ variant }
          size='lg'
          onClick={() => retakeImage()}
        />
      )
    }
    else {
      return (
        <Button
          text= { i18next.t('button.capture') }
          variant={ main ? 'outline' : 'outlineborder' }
          size='lg'
          onClick={capture}
        />
      )
    }
  }

  const renderBottomButtons = () => {
    
    {/* We always want in the right, the button we want the user to click/tap */}
    
    if(isAllGoodMemoized){
      return (
        <>
          { renderCaptureButton() }
          { renderNextButton(true) }
        </>
      )  
      
    }
    else {
      return (
        <>
          { renderNextButton() }
          { renderCaptureButton(true) }
        </>
      )
      
    }
  }

  const renderIcon = (answer) => {

    if(isMobile){
      var icon_size = 40
    } else {
      var icon_size = 60
    }


    if(answer === true){
      return (
        <div className='answer-icon yes'>
          <DoneRoundedIcon sx={{ color: isMobile ? 'green' : '#AAFF00', fontSize:icon_size }} />
        </div>
      )
    }

    if(answer === false){
      return (
        <div className='answer-icon no'>
          <CloseRoundedIcon sx={{ color: '#EE4B2B', fontSize:icon_size }} />
        </div>
      )
    }

    // When we don't want to show icons we send an empty div for the grid not to break.
    if(answer === 'empty'){
      return (
        <div className='answer-icon'>
        </div>
      )
    }

    // we use to display question marks on this cases but it was requested not to.
    if(answer === 'n/a' || typeof anwswer === 'undefined'){
      return (
        <div className='answer-icon question-mark'>
          <CloseRoundedIcon sx={{ color: '#EE4B2B', fontSize:icon_size }} />
        </div>
      )
    }
      

  } 

  const renderRow = (id, question, answer, isIssue = false) => {
    
    if(isIssue){
      var className = 'issue'
    }
    else{
      var className = 'question'
    }
    
    return (
      <>
        <div key={id} className={className}> {question} </div>
        { renderIcon(answer) }
      </>
    )

  }

  const issues = () => {

    var arr = []

    if(state.imageFeatures['is_mouth_open']){
      arr.push(i18next.t('requirements.mouth_open'))
    }

    if(state.imageFeatures['are_eyes_closed']){
      arr.push(i18next.t('requirements.eyes_closed'))
    }

    if(state.imageFeatures['proximity'] === 'close'){
      arr.push(i18next.t('requirements.too_close'))
    }

    if(state.imageFeatures['proximity'] === 'far'){
      arr.push(i18next.t('requirements.too_far'))
    }

    return arr.join(' | ')


  }

	return (
		
    <>

    { state.uploading &&
      <>
      <div id="simple-spinner"> </div>
      </>
    }
    
    { !state.uploading && 
      <Container>
			<StyledGeneral>
				<ImageComponent />


          <div className='answers'>



            {/* Renders one grid row per each imageData object */}

            {imageData.map(({ id, question, name, value }, i) => {


              let answer = state.imageFeatures[name]

              if(name == "eyeglasses")
                answer = !answer

              return renderRow(id, question, answer) 

            })}


            {/* issues string */}
            { issues() && renderRow('10', issues() , 'empty', true) }
            {/* Renders rows with extra information, using grids autoplacement */}


          </div>
			
			</StyledGeneral>

			<div className='desktop bottom-buttons'>
        { renderBottomButtons() }
			</div>
			
      <MBContent>
            {renderBottomButtons() }
			</MBContent>

			<PhoneCamera camRef={camRef} />
		
      <ConfirmDialog />

		</Container>
    }

    </>
    
	);
}
