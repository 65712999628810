import { useState } from 'react';
// import { useData } from '../../context/data';

export const useImageAction = () => {
	// const { state } = useData();
	const [values, setValues] = useState({});
	const [disabled, setDisabled] = useState(false);

	const handleClick = (name, value) => {
		const newValues = { ...values, [name]: value };
		setValues(newValues);

		const v = Object.values(newValues);

		if (v.length === 5) {
			if (v.includes('false')) setDisabled(true);
			else setDisabled(false);
		}
	};

	return {
		handleClick,
		values,
		disabled,
	};
};
