import React from 'react';
import Webcam from 'react-webcam';
import { useData } from '../../context/data';
import { StyledWebCam } from './image-review.styled';

const videoConstraints = {
	facingMode: 'user',
};

export default function ImageReview() {

	const { state } = useData();

	return (
		<StyledWebCam>
			{state.processedImage ? (
				<img src={state.processedImage} alt='' />
			): <p> Missing Processed Image </p> }
		</StyledWebCam>
	);
}
