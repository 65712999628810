import React from 'react';

import PageLayout from '../layouts/page/page.layout';
import ImageContent from '../components/image-content/image-content.comp';
import { useIsMobile } from '../utils/hooks/use-is-mobile';
import i18next from '../components/i18n'


const ImagePreview = ({location}) => {
	const isMobile = useIsMobile();

	return (
		<PageLayout
			subTopic={
				isMobile
					? i18next.t('image.subtitle')
					: i18next.t('image.subtitle')
			}
			title='Image Manipulation'
			location={location}
		>
			<ImageContent />
		</PageLayout>
	);
};

export default ImagePreview;
